//import * as React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home } from "./Home";

import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"

import { Header } from "../components/Header";

const CV = () => {
  window.location.replace("https://drive.google.com/file/d/1VI9WfWXr4IF_wihRqUMn0xjDpNg47ME_/view?usp=sharing")
  return null;
}

const AICC = () => {
  window.location.replace("https://vpalma.notion.site/AICC-Quizz-Tools-14aa9fe458dc44fabb09f95b3f2b92c7")
  return null;
}

const LouisNotion = () => {
  window.location.replace("https://vpalma.notion.site/Louis-91c234da229541c7969a42f1b4fb1fd2")
  return null;
}

export const Root = () => (
  <Router>
    <ChakraProvider theme={theme}>
      <Header />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/cv/*" element={<CV/>}/>
        <Route path="/aicc/*" element={<AICC/>}/>
        <Route path="/louis/*" element={<LouisNotion/>}/>
      </Routes>
    </ChakraProvider>
  </Router>
)
