import * as React from "react"
import { Grid, HStack, IconButton } from "@chakra-ui/react"
import { ColorModeSwitcher } from "../ColorModeSwitcher"
import { AiOutlineGithub } from "react-icons/ai"
export const Header = () => (
    <Grid pos="fixed" minH={20} minW="100%" p={2} mr={0}>
        <HStack justifySelf="flex-end" pr={2}>
        <IconButton colorScheme="gray" aria-label='GitHub link' icon={<AiOutlineGithub/>} onClick={() => (window.open("https://github.com/vincentpalma"))}/>
        <ColorModeSwitcher />
        </HStack>
    </Grid>
)