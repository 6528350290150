import * as React from "react"
import { Flex, Heading, HStack } from "@chakra-ui/react"

export const Home = () => (
    <>
    <Flex minH="100vh" justifyContent="center">
        <HStack m={2}>
            <Heading justifySelf="center">Hi, this website is under construction<span id="cursor">_</span></Heading>
        </HStack>
        <style>
            {`
                #cursor{
                    animation: blink 1s step-start infinite;
                    text-underline-offset: -0.5em !important;
                }
                @keyframes blink{
                    50%{
                        opacity: 0;
                    }
                }
            `}
        </style>
    </Flex>
    </>
)